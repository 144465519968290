<template>
  <b-modal
    id="modal-order-cancel-all"
    centered
    hide-header
  >
    Are you sure want to cancel all orders?
    <template #modal-footer>
      <b-button class="btn-wz-cancel" @click="$bvModal.hide('modal-order-cancel-all')">No</b-button>
      <b-button class="btn-wz-proceed" @click="cancelAllOrder">Yes</b-button>
    </template>
  </b-modal>
</template>
<script>
import { toaster } from "@/_helpers";

export default {
  name: "ModalOrderCancelAll",
  props: ["orderType"],
  methods: {
    async cancelAllOrder() {
      try {
        const res = await this.$api.order.cancelAllOrder(this.orderType)
        if (res.status == 200) {
          toaster.make(res.data.message, "success")
          this.$emit("refreshList")
          this.$bvModal.hide("modal-order-cancel-all")
        }
        else toaster.make(res.data.message, "danger")
      } catch (error) {
        console.error(error)
      }
    },
  },
};
</script>
<style scoped>
button {
  padding: 8px 12px;
  text-transform: uppercase;
  font-size: 12px !important;  
  letter-spacing: .5px !important;
}
</style>