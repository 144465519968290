<template>
  <b-modal
    id="modal-order-cancel"
    modal-class="p-0 text-center"
    centered
    hide-header
    @ok="cancelOrder(items)"
  >
    Are you sure you want to cancel this order?
  </b-modal>
</template>
<script>
import { toaster } from "@/_helpers";
import axios from "axios";

export default {
  name: "ModalOrderCancel",
  props: {
    items: null,
    is_reseller: null,
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
  },
  methods: {
    cancelOrder(items) {
      let url = "";
      let data
      if (!this.is_reseller) {
        url = "users/order/cancelorder";
        data = { order_id: items.id };
      } else {
        url = "users/reseller/cancel_order";
        data = { order_number: items.order_number };
      }
      axios
        .post(this.apiUrl + url, data, {
          headers: {
            api_key: this.apiKey,
            token: this.$store.getters.getUserToken,
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          toaster.make("Order Has Been Canceled", "success");
          this.$emit("cancelOrderResponse");
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
<style></style>