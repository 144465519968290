<template>
  <b-modal
    id="modal-order-received"
    modal-class="p-0"
    centered
    hide-header
    @ok="orderReceived(items.id)"
  >
    <div class="modal-dialog" role="document" style="display: contents">
      <div class="modal-content" style="display: contents">
        <div class="modal-body p-0 pt-3">
          Are you sure your order has been received?
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { toaster } from "@/_helpers";
import axios from "axios";

export default {
  name: "ModalOrderReceive",
  props: {
    items: undefined,
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
  },
  methods: {
    orderReceived(id) {
      axios
        .patch(
          this.apiUrl + "users/order/update/" + id,
          {},
          {
            headers: {
              api_key: this.apiKey,
              token: this.$store.getters.getUserToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          toaster.make("Order Has Been Updated", "success");
          this.$emit("receiveOrderResponse");
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
<style></style>