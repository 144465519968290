<template>
  <div id="page">
    <section class="product-list-heading box-my-account mb-0">
      <div class="container-fluid">
        <div class="row">
          <ProfileNavbar :parentData="userData.full_name"></ProfileNavbar>
          <div id="panel" class="col-md-9 py-5 px-lg-4">
            <b-row>
              <b-col cols="12">
                <h2>Recent Order</h2>
              </b-col>
              <b-col cols="12">
                <!-- Desktop View -->
                <div class="d-lg-inline-flex d-none order-status-badge-list" :class="{ 'is-loading': isLoading }">
                  <span v-for="(orderStatus, i) in orderFilterOptions" :key="`order-status-${i}`">
                    <input :id="`order-status-label-${i}`" class="order-status-badge" type="radio" v-model="selectedOrderFilter" :value="orderStatus.value" hidden />
                    <label class="order-status-label" :for="`order-status-label-${i}`">{{ orderStatus.text }}</label>
                  </span>
                </div>
                <!-- Mobile View -->
                <span class="order-status-select-mobile d-lg-none" :class="{ 'is-loading': isLoading }" @click="showSweepingModal = true">
                  {{ orderFilterOptions.find(el => el.value == selectedOrderFilter).text }} <fawesome-pro variant="fas" icon="chevron-down" class="ml-1" />
                </span>
                <!-- Cancel All Order Button -->
                <div class="float-right" v-if="/unfinished/i.test(orderType)">
                  <b-button
                  class="btn-wz-cancel"
                  @click="canceledOrderGroupType = orderType" 
                  v-b-modal.modal-order-cancel-all
                  :disabled="!recentOrderData.length"
                  >
                    Cancel All
                  </b-button>
                </div>
              </b-col>
              <!-- Takeout Navbar (Revamp UX Recent Order) -->
              <!-- <nav>
                <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                  <a
                    class="nav-item nav-link"
                    v-bind:class="navBarUnfinish"
                    id="nav-unfinished-tab"
                    href="#panel"
                    @click="changeRecentOrderNavbar('unfinished')"
                    >Unfinished</a
                  >
                  <a
                    class="nav-item nav-link"
                    v-bind:class="navBarUnpaid"
                    id="nav-unpaid-tab"
                    href="#panel"
                    @click="changeRecentOrderNavbar('unpaid')"
                    >Unpaid</a
                  >
                  <a
                    class="nav-item nav-link"
                    v-bind:class="navBarPacking"
                    id="nav-packing-tab"
                    href="#panel"
                    @click="changeRecentOrderNavbar('packing')"
                    >Packing</a
                  >
                  <a
                    class="nav-item nav-link"
                    v-bind:class="navBarShipping"
                    id="nav-shipping-tab"
                    href="#panel"
                    @click="changeRecentOrderNavbar('shipping')"
                    >Shipping</a
                  >
                  <a
                    class="nav-item nav-link"
                    v-bind:class="navBarDelivered"
                    id="nav-delivered-tab"
                    href="#panel"
                    @click="changeRecentOrderNavbar('delivered')"
                    >Delivered</a
                  >
                  <a
                    class="nav-item nav-link"
                    v-bind:class="navBarCancelled"
                    id="nav-cancelled-tab"
                    href="#panel"
                    @click="changeRecentOrderNavbar('cancelled')"
                    >Cancelled</a
                  >
                </div>
              </nav> -->
              <!-- <div class="tab-content" id="nav-tabContent">
                <div
                  class="tab-pane fade show active table-responsive"
                  role="tabpanel"
                  aria-labelledby="nav-unfinished-tab"
                > -->
              <b-col cols="12" class="mt-3">
                <b-skeleton-wrapper :loading="isLoading">
                  <template #loading>
                    <div v-for="i in 4" class="mb-4 p-3 card-recent-order" :key="`order-status-skeleton-${i}`">
                      <div class="d-flex justify-content-between mb-1">
                        <b-skeleton width="15%" />
                        <b-skeleton width="15%" />
                      </div>
                      <div class="d-flex align-items-start p-16">
                        <b-skeleton-img class="rounded" width="120px" height="120px" />
                        <div class="flex-grow-1 ml-2">
                          <b-skeleton width="20%" class="mb-2" />
                          <b-skeleton width="20%" class="mb-2" />
                          <b-skeleton width="30%" class="mb-2" />
                        </div>
                      </div>
                      <div class="d-flex align-items-center justify-content-between">
                        <b-skeleton width="25%" />
                        <b-skeleton width="25%" />
                      </div>
                    </div>
                  </template>

                  <div
                    class="image-unfinish text-center mt-5"
                    v-if="recentOrderData.length == 0"
                  >
                    <img src="@/assets/img/shoping-bag-empty.png" alt="" />
                  </div>
                  <!-- If order exist -->
                  <template v-else>
                    <div
                      v-for="(order, indexOrder) in recentOrderData"
                      :key="indexOrder"
                    >
                      <!-- Packing/Shipping/Delivered/Paid Order Status -->
                      <template v-if="/paid|packed|delivered|allpaid/.test(orderType)">
                        <div
                          class="mb-4 p-3 card-recent-order"
                          v-for="(merchant, indexMerchant) in order.sellers"
                          :key="indexMerchant"
                        >
                          <!-- Order status -->
                          <div class="row">
                            <div class="col-6 status-tags bold">
                              {{ merchant.seller_name }}
                            </div>
                            <div class="text-right col-6 status-tags bold" :style="{'color' : getOrderStatusColor(order)}">
                              {{ getOrderStatusText(order.status_text, merchant) }}
                            </div>
                          </div>
                          <!-- Order number & payment time -->
                          <div class="recent-order-info row">
                            <div class="col-6">
                              <div>
                                Order Number
                                <span
                                  class="bold text-gold text-nowrap ml-lg-2"
                                >
                                  {{ merchant.order_number }}
                                </span>
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="text-right">
                                {{ formatDate(order.order_date) }}
                              </div>
                            </div>
                          </div>
                          <!-- All product by merchant  -->
                          <div class="row py-3">
                            <div class="col-md-12">
                              <div
                                class="product"
                                :class="{
                                  'mb-0':
                                    indexItem == merchant.items.length - 1,
                                }"
                                v-for="(product, indexItem) in merchant.items"
                                :key="indexItem"
                              >
                                <router-link :to="'/product/' + $helpers.format.slug(`${product.product_name} ${product.product_id}`)">
                                  <img
                                    class="w-120px"
                                    :src="product.image_url"
                                    :alt="setProductImgAltText(product)"
                                    @error="replaceByDefaultImage"
                                  />
                                </router-link>
                                <div class="ml-3">
                                  <router-link class="product-brand-big text-black" :to="'/product/' + $helpers.format.slug(`${product.product_name} ${product.product_id}`)">
                                    {{ product.product_name }}
                                  </router-link>
                                  <div class="price text-gold">
                                    {{ $helpers.format.price(product.price_display) }}
                                  </div>
                                  <div class="d-lg-flex">
                                    <div class="payment-detail">
                                      Color :
                                      <span class="no-bold">
                                        {{ product.color }}
                                      </span>
                                    </div>
                                    <div class="payment-detail ml-lg-3">
                                      Size :
                                      <span class="no-bold">
                                        {{ product.size }}
                                      </span>
                                    </div>
                                    <div class="payment-detail ml-lg-3">
                                      QTY :
                                      <span class="no-bold">
                                        {{ product.quantity }}
                                      </span>
                                    </div>
                                  </div>
                                  <!-- Product Additional Info -->
                                  <div class="product-status-badge preorder-badge" v-if="product.status_stock == false">Pre-order</div>
                                  <div class="preorder-estimated d-flex flex-lg-row flex-column" v-if="product.estimated_time_arrival_millis">
                                    <div>Estimated Delivery:</div>
                                    <b class="ml-lg-1">{{ formatEpochMsToLocalTime(product.estimated_time_arrival_millis) }}</b> 
                                  </div>
                                </div>
                              </div>
                              <!-- Lastest track order status -->
                              <div
                                class="
                                  text-center
                                  bg-grey
                                  p-20
                                  mt-1
                                  recent-order-info
                                "
                                v-if="
                                  /packed|delivered/.test(orderType) &&
                                  merchant.track_order &&
                                  lastTrackOrder[indexOrder]
                                "
                              >
                                <img
                                  v-if="/packed/.test(orderType)"
                                  width="25"
                                  src="@/assets/img/icon-delivery.png"
                                  alt=""
                                />
                                <img
                                  v-else-if="/delivered/.test(orderType)"
                                  width="25"
                                  src="@/assets/img/icon-receive.png"
                                  alt=""
                                />
                                {{ lastTrackOrder[indexOrder] }}
                              </div>
                            </div>
                          </div>
                          <!-- Commission & Payment Total -->
                          <div class="row recent-order-payment">
                            <div class="col-6">
                              <div class="bold" v-if="order.reseller_id">
                                Commission :
                              </div>
                              <div class="bold">Payment Total :</div>
                            </div>
                            <div class="text-right col-6">
                              <div
                                class="bold text-gold"
                                v-if="order.reseller_id"
                              >
                                {{ merchant.reseller_commission > 0 ? $helpers.format.price(merchant.reseller_commission) : `-` }}
                              </div>
                              <div class="bold text-gold">
                                {{ $helpers.format.price(merchant.total) }}
                              </div>
                            </div>
                          </div>
                          <div class="row pt-3">
                            <!-- Button view detail order & download invoice -->
                            <div
                              class="
                                d-flex
                                justify-content-between justify-content-lg-start
                                col-md-6 col-12
                              "
                            >
                              <router-link
                                :to="`/order-detail/${merchant.order_number}`"
                                class="btn btn-wz-clear btn-w-48"
                                @click.native="$store.dispatch('setOrderDetail', order)"
                                tag="button"
                              >
                                <b-img :src="require('@/assets/img/gift-icon.svg')" alt="" v-if="order.id_encrypt && order.email_voice" class="mr-1" width="16" />
                                <span>View</span>
                              </router-link>
                              <!-- //* Takeout invoice for reseller -->
                              <!-- Invoice for business partner -->
                              <!-- <button
                                class="btn btn-wz-clear btn-w-48 ml-lg-3"
                                @click="selectedOrderNumber = merchant.order_number; selectedMerchantId = merchant.id"
                                v-b-modal="'modal-print-invoice'"
                                v-if="resellerData && order.reseller_id"
                              >
                                <fawesome-pro variant="far" icon="arrow-to-bottom" class="mr-2" size="lg" /> Invoice
                              </button> -->
                              <!-- Default invoice for customer -->
                              <button
                                class="btn btn-wz-clear btn-w-48 ml-lg-3"
                                @click="getDefaultInvoice(merchant.id)"
                              >
                                <fawesome-pro variant="far" icon="arrow-to-bottom" class="mr-2" size="lg" /> Invoice
                              </button>
                            </div>
                            <!-- Button review order / order received / pickup code -->
                            <div
                              class="col-md-6 col-12 mt-2 mt-lg-0 text-right"
                              v-if="/packed|delivered|allpaid/.test(orderType)"
                            >
                              <template v-if="!merchant.order_status.completed && merchant.order_status.shipped && merchant.order_status.processing">
                              <!-- <template v-if="/packed/.test(orderType)"> -->
                                <button
                                  type="button"
                                  v-b-modal="'modal-order-received'"
                                  @click="selectedOrder = merchant"
                                  class="btn btn-wz-transparent btn-w-100"
                                  v-if="merchant.shipment_type == 0"
                                >
                                  Order Received
                                </button>
                                <div
                                  v-else-if="
                                    merchant.shipment_type == 1 &&
                                    merchant.pickup_code
                                  "
                                  class="pickup-code"
                                >
                                  <div>Ready to pickup</div>
                                  <div>
                                    Pickup code :
                                    <span
                                      class="bold cursor-pointer"
                                      @click="copy(merchant.pickup_code)"
                                    >
                                      {{ merchant.pickup_code }}
                                    </span>
                                  </div>
                                </div>
                              </template>
                              <!-- <template v-if="/delivered/.test(orderType) && merchant.items.find(el => !el.is_reviewed)"> -->
                              <template v-if="merchant.order_status.completed && merchant.items.find(el => !el.is_reviewed)">
                                <!-- <button
                                  type="button"
                                  v-b-modal="'modal-review'"
                                  @click="setModalReviewItems(merchant.items)"
                                  class="btn btn-wz-transparent btn-w-100"
                                >
                                  Review Order
                                </button> -->
                                <router-link
                                  class="btn btn-wz-transparent btn-w-100"
                                  to="/product-review"
                                >
                                  Review Order
                                </router-link>
                              </template>
                            </div>
                          </div>
                        </div>
                      </template>
                      <!-- Unpaid/unfinished/cancelled order status -->
                      <div class="mb-4 p-3 card-recent-order" v-else>
                        <!-- Order status -->
                        <div class="row">
                          <div class="col-12 status-tags text-right bold" :style="{'color' : getOrderStatusColor(order)}">
                            <!-- {{ status }} -->
                            {{ getOrderStatusText(order.status_text) }}
                          </div>
                        </div>
                        <!-- Order number & payment time -->
                        <div class="row recent-order-info">
                          <div class="col-6">
                            <div>
                              Order Number
                              <span class="bold text-gold text no wrap ml-lg-2">
                                {{ order.order_number }}
                              </span>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="text-right">
                              {{ formatDate(order.order_date) }}
                            </div>
                          </div>
                        </div>
                        <!-- All product by order  -->
                        <div class="row py-3">
                          <div class="col-md-12">
                            <div
                              v-for="(merchant, indexMerchant) in order.sellers"
                              :key="indexMerchant"
                            >
                              <div
                                class="product"
                                :class="{
                                  'mb-0':
                                    indexItem == merchant.items.length - 1 &&
                                    indexMerchant == order.sellers.length - 1,
                                }"
                                v-for="(product, indexItem) in merchant.items"
                                :key="indexItem"
                              >
                                <router-link :to="'/product/' + $helpers.format.slug(`${product.product_name} ${product.product_id}`)">
                                  <img
                                    class="w-120px"
                                    :src="product.image_url"
                                    :alt="setProductImgAltText(product)"
                                    @error="replaceByDefaultImage"
                                  />
                                </router-link>
                                <div class="ml-3">
                                  <router-link class="product-brand-big text-black" :to="'/product/' + $helpers.format.slug(`${product.product_name} ${product.product_id}`)">
                                    {{ product.product_name }}
                                  </router-link>
                                  <div class="price text-gold">
                                    {{ $helpers.format.price(product.price_display) }}
                                  </div>
                                  <div class="d-lg-flex">
                                    <div class="payment-detail">
                                      Color :
                                      <span class="no-bold">
                                        {{ product.color }}
                                      </span>
                                    </div>
                                    <div class="payment-detail ml-lg-3">
                                      Size :
                                      <span class="no-bold">
                                        {{ product.size }}
                                      </span>
                                    </div>
                                    <div class="payment-detail ml-lg-3">
                                      QTY :
                                      <span class="no-bold">
                                        {{ product.quantity }}
                                      </span>
                                    </div>
                                  </div>
                                  <!-- Product Additional Info -->
                                  <div class="product-status-badge preorder-badge" v-if="product.status_stock == false">Pre-order</div>
                                  <div class="preorder-estimated d-flex flex-lg-row flex-column" v-if="product.estimated_time_arrival_millis">
                                    <div>Estimated Delivery:</div>
                                    <b class="ml-lg-1">{{ formatEpochMsToLocalTime(product.estimated_time_arrival_millis) }}</b> 
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="
                                text-center
                                bg-grey
                                p-20
                                mt-1
                                recent-order-info
                              "
                              v-if="/unfinished/.test(orderType)"
                            >
                              Please complete the payment before
                              <b>{{ formatDate(order.expired_date) }}</b>
                            </div>
                            <div
                              class="
                                text-center
                                bg-grey
                                p-20
                                mt-1
                                recent-order-info
                              "
                              v-else-if="/cancelled/.test(orderType)"
                            >
                              This order has been cancelled
                            </div>
                          </div>
                        </div>
                        <!-- Commission & Payment Total -->
                        <div class="row recent-order-payment">
                          <div class="col-6">
                            <div class="bold" v-if="order.reseller_id">
                              Commission :
                            </div>
                            <div class="bold">Payment Total :</div>
                          </div>
                          <div class="text-right col-6">
                            <div
                              class="bold text-gold"
                              v-if="order.reseller_id"
                            >
                              {{ order.reseller_total_commission > 0 ? $helpers.format.price(order.reseller_total_commission) : `-` }}
                            </div>
                            <div class="bold text-gold">
                              {{ $helpers.format.price(order.total) }}
                            </div>
                          </div>
                        </div>
                        <div class="row pt-3">
                          <div class="col-md-6 col-12">
                            <!-- Button view detail order -->
                            <router-link
                              :to="`/order-detail/${order.order_number}`"
                              class="btn btn-wz-clear btn-w-100"
                              @click.native="$store.dispatch('setOrderDetail', order)"
                            >
                              <b-img :src="require('@/assets/img/gift-icon.svg')" alt="" v-if="order.id_encrypt && order.email_voice" class="mr-1" width="16" />
                              <span>View</span>
                            </router-link>
                          </div>
                          <!-- Button pay now -->
                          <div
                            class="
                              d-flex
                              justify-content-between justify-content-lg-end
                              col-md-6 col-12
                              mt-2 mt-lg-0
                              text-right
                            "
                            v-if="/unfinished/.test(orderType)"
                          >
                            <button
                              type="button"
                              v-b-modal="'modal-order-cancel'"
                              @click="selectedOrder = order"
                              class="btn btn-wz-cancel"
                              :class="
                                /gopay|shopeepay|qris/i.test(
                                  order.payment_method
                                )
                                  ? 'btn-w-100'
                                  : 'btn-w-48'
                              "
                            >
                              Cancel Order
                            </button>
                            <router-link
                              class="btn btn-wz-proceed btn-w-48 ml-lg-3"
                              :to="`/order-completed/${order.order_number}`"
                              v-if="!order.payment_api_integration"
                            >
                              Pay Now
                            </router-link>
                            <template
                              v-else-if="
                                !/gopay|shopeepay|qris/i.test(
                                  order.payment_method
                                )
                              "
                            >
                              <a
                                v-if="order.midtrans_info"
                                :href="order.midtrans_info.redirect_url"
                                class="btn btn-wz-proceed btn-w-48 ml-lg-3"
                              >
                                Pay Now
                              </a>
                              <a
                                v-else-if="
                                  Object.keys(order).filter(
                                    (el) =>
                                      /(?![voice_message])\w+_url/i.test(el) &&
                                      order[el]
                                  ).length
                                "
                                :href="
                                  order.atome_url ||
                                  order.indodana_url ||
                                  order.kredivo_url
                                "
                                class="btn btn-wz-proceed ml-3"
                              >
                                Pay Now
                              </a>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <hr /> -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalOrder"
                      :per-page="perPage"
                      align="center"
                      @change="pageChange"
                    >
                    </b-pagination>
                  </template>
                </b-skeleton-wrapper>
              </b-col>
              <!-- </div>
            </div> -->
            </b-row>
          </div>
        </div>
      </div>
    </section>
    <ModalOrderCancel
      v-if="selectedOrder"
      :items="selectedOrder"
      v-on:cancelOrderResponse="
        recentOrderData.splice(
          recentOrderData.findIndex((el) => el.id == selectedOrder.id),
          1
        )
      "
    />
    <ModalOrderReceive
      v-if="selectedOrder"
      :items="selectedOrder"
      v-on:receiveOrderResponse="changeRecentOrderNavbar('delivered', 1)"
    />
    <!-- <ModalPrintInvoice v-if="selectedOrderNumber" :order_number="selectedOrderNumber" :merchant_id="selectedMerchantId" /> -->
    <!-- <ModalReview @refreshList="getRecentOrderData" v-if="selectedReview" :items="selectedReview"></ModalReview> -->
    <ModalOrderCancelAll :orderType="canceledOrderGroupType" @refreshList="getRecentOrderData" />
    <!-- Swipeable Bottom Sheet Modal  -->
    <SwipeableModalBottom v-model="showSweepingModal" @open="selectedOrderFilterMobile = selectedOrderFilter">
      <template #modal-content>
        <b-container class="mb-3">
        <b-row no-gutters>
          <b-col cols="12" class="text-center border-bottom pb-2">Show Recent Order</b-col>
          <b-col cols="12" class="mt-3">
            <MyInputFormRadioGold v-model="selectedOrderFilterMobile" :options="orderFilterOptions" name="order-status" />
          </b-col>
          <b-col cols="12" class="mt-3">
            <b-button class="btn-wz-proceed" block @click="showSweepingModal = false; selectedOrderFilter = selectedOrderFilterMobile">Submit</b-button>
          </b-col>
        </b-row>
      </b-container>
      </template>
    </SwipeableModalBottom>
  </div>
</template>

<script>
import { toaster, formatter } from "@/_helpers";
import ProfileNavbar from "@/components/mains/ProfileNavbar.vue";
// import ModalReview from "@/components/modals/ModalReview.vue";
import ModalOrderCancel from "@/components/modals/ModalOrderCancel.vue";
import ModalOrderReceive from "@/components/modals/ModalOrderReceive.vue";
// import ModalPrintInvoice from "@/components/modals/ModalPrintInvoice.vue";
import ModalOrderCancelAll from "@/components/modals/ModalOrderCancelAll.vue";
const MyInputFormRadioGold = () => import("@/components/form/MyInputFormRadioGold");
const SwipeableModalBottom = () => import("@/components/modals/SwipeableModalBottom");

import axios from "axios";
import moment from "moment";

export default {
  name: "RecentOrderProfile",
  components: {
    ProfileNavbar,
    ModalOrderCancel,
    ModalOrderReceive,
    // ModalPrintInvoice,
    ModalOrderCancelAll,
    // ModalReview,
    MyInputFormRadioGold,
    SwipeableModalBottom,
  },
  data() {
    return {
      // navBarUnfinish: "active",
      // navBarUnpaid: "",
      // navBarPacking: "",
      // navBarShipping: "",
      // navBarDelivered: "",
      // navBarCancelled: "",
      status: "",
      recentOrderData: [],
      lastTrackOrder: [],
      totalOrder: undefined,
      token: this.$store.getters.getUserToken,

      orderType: "",
      selectedOrder: undefined,
      // selectedReview: undefined,
      // selectedOrderNumber: null,
      selectedMerchantId: null,
      currentPage: 1,
      perPage: 10,

      canceledOrderGroupType: "",

      selectedOrderFilter: 'allpaid',
      selectedOrderFilterMobile: null, //* v-model order status for sweeping modal (mobile view)
      orderFilterOptions: [
        { value: 'unfinished', text: 'Unfinished' },
        // { value: 'unpaid', text: 'Unpaid' },
        { value: 'packing', text: 'Packing' },
        { value: 'shipping', text: 'Shipping' },
        { value: 'delivered', text: 'Delivered' },
        { value: 'cancelled', text: 'Cancelled' },
        { value: 'allpaid', text: 'Paid' },
      ],

      isLoading: false,

      showSweepingModal: false,
    };
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
    userData() {
      return this.$store.getters.getUserData;
    },
    resellerData() {
      return this.$store.getters.getResellerData;
    },
  },
  mounted() {
    const query = {
      status: this.$route.query.status || "allpaid",
      p: this.$route.query.p,
    };
    this.changeRecentOrderNavbar(query.status, query.p);
    this.selectedOrderFilter = query.status;
  },
  methods: {
    getOrderStatusColor(order) {
      if (/CH|WC/.test(order.status)) return '#DB7C3B'; //* Unfinished Order
      if (/CA|EX/.test(order.status)) return '#D32F2F'; //* Cancelled / Expired Order
      return '#4CAF50'; //* Packing / Shipping / Delivered Order
    },
    getOrderStatusText(status_text, merchant) {
      if (!merchant) return status_text;

      const order_status = merchant.order_status;
      if (order_status.completed) return order_status.completed_detail.last_detail_status || 'Receive By Customer';
      else if (order_status.shipped) return order_status.shipped_detail.last_detail_status || 'On Delivery';
      else if (order_status.processing) return order_status.processing_detail.last_detail_status || 'Packing';
    },
    getRecentOrderData() {
      this.isLoading = true;
      axios
        .get(
          this.apiUrl +
            "users/order/lists?type=" +
            this.orderType +
            "&offset=" +
            (this.currentPage - 1) +
            "&limit=10",
          {
            headers: {
              api_key: this.apiKey,
              token: this.token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (this.orderType == "paid") {
            res.data.data.map(
              (el) =>
                (el.sellers = el.sellers.filter(
                  (s) => !s.order_status.completed && !s.order_status.shipped
                ))
            );
          } else if (this.orderType == "packed") {
            res.data.data.map(
              (el) =>
                (el.sellers = el.sellers.filter(
                  (s) => !s.order_status.completed && s.order_status.shipped
                ))
            );
          } else if (this.orderType == "delivered") {
            res.data.data.map(
              (el) =>
                (el.sellers = el.sellers.filter(
                  (s) => s.order_status.completed
                ))
            );
          }
          this.recentOrderData = res.data.data;
          if (["packed", "delivered"].includes(this.orderType)) {
            this.trackOrder(this.recentOrderData);
          }
          this.totalOrder = res.data.meta.total;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error)
        });
    },
    changeRecentOrderNavbar(navTo, page) {
      this.status = navTo.charAt(0).toUpperCase() + navTo.slice(1);
      // this.navBarUnfinish = "";
      // this.navBarUnpaid = "";
      // this.navBarPacking = "";
      // this.navBarShipping = "";
      // this.navBarDelivered = "";
      // this.navBarCancelled = "";

      if (navTo == "unfinished" || !navTo) {
        // this.navBarUnfinish = "active";
        // this.orderType = "checked";
        this.orderType = "unfinished"; //* orderType unfinished => unfinished & unpaid order
      } 
      //*  Take out unpaid order option
      // else if (navTo == "unpaid") {
      //   this.navBarUnpaid = "active";
      //   this.orderType = "new";
      // } 
      else if (navTo == "packing") {
        // this.navBarPacking = "active";
        this.orderType = "paid";
      } else if (navTo == "shipping") {
        // this.navBarShipping = "active";
        this.orderType = "packed";
      } else if (navTo == "delivered") {
        // this.navBarDelivered = "active";
        this.orderType = "delivered";
      } else if (navTo == "cancelled") {
        // this.navBarCancelled = "active";
        this.orderType = "cancelled";
      } else if (navTo == "allpaid") {
        this.orderType = "allpaid";
      }
      this.currentPage = page || 1;
      this.setQuery(navTo, this.currentPage);
      this.getRecentOrderData();
    },
    // TODO alogritma untuk trackOrder belum optimal
    async trackOrder(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].sellers[0].tracking_number) {
          const param = {
            waybill: data[i].sellers[0].tracking_number,
            courier: data[i].sellers[0].courier_code,
          };
          const response = await axios.post(
            this.apiUrl + "users/order/track_order",
            param,
            {
              headers: {
                api_key: this.apiKey,
                token: this.$store.getters.getUserToken,
                "Content-Type": "application/json",
              },
            }
          );
          if (response.status === 200 && response.data.data) {
            this.lastTrackOrder.push(
              response.data.data.manifest[0].manifest_description
            );
          } else {
            this.lastTrackOrder.push(null);
          }
        } else {
          this.lastTrackOrder.push(null);
        }
      }
    },
    async getDefaultInvoice(id) {
      try {
        const res = await this.$api.order.getDefaultInvoice(id);
        if (res.status === 200) window.open(res.data.data);
        else toaster.make(res.data.message, "danger");
      } catch (e) {
        console.error(e);
      }
    },
    replaceByDefaultImage(img) {
      img.target.src = require("@/assets/img/product-default.png");
    },
    async copy(value) {
      await navigator.clipboard.writeText(value);
      toaster.make("Pickup code has been copied to your clipboard", "success");
    },
    // setModalReviewItems(items) {     
    //   this.selectedReview = items
    //   const notReviewed =  this.selectedReview.find(el => el.is_reviewed == false)
    //   if (notReviewed) {
    //     // Set initial review data for each product
    //     for (const item of this.selectedReview) {
    //       if(item.is_reviewed == false) {
    //         item.review = [{
    //           rating: 0,
    //           comment: "",
    //           tag: "",
    //           path_image: [],
    //           file_image: [],
    //           anonymous: false,
    //         }]
    //       }
    //     }
    //   }
    // },
    // Pagination Methods START
    pageChange(page) {
      this.currentPage = page;
      this.setQuery(this.$route.query.status, page);
      this.getRecentOrderData();

      //For Scrolling Animation and Offset
      const yOffset = -70;
      const element = document.getElementById("panel");
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    },
    // Pagination Methods END
    setQuery(status, page) {
      const query = {
        status: status,
        p: page,
      };
      this.$router.push({ query: query }).catch(() => {});
    },
    formatDate(value) {
      return moment.utc(value).format("DD MMMM YYYY | HH:MM");
    },
    formatEpochMsToLocalTime(value) {
      return formatter.epochMsToLocalTime(value, "DD MMMM YYYY")
    },
    setProductImgAltText(product) {
      return `${product.brand_name} ${product.product_name}`
    },
  },
  watch: {
    //* Only run when click transaction and order journey from profile navbar
    "$route.query.status"(val){
      this.selectedOrderFilter = val;
    },
    selectedOrderFilter(val) {
      this.changeRecentOrderNavbar(val, 1);
    },
  },
};
</script>

<style scoped>
/* Custom Radio Button START */
.order-status-radio-item input[type="radio"] {
  display: none;
}
.order-status-radio-item + .order-status-radio-item {
  margin-top: 15px;
}
.order-status-radio-item label {
  display: block;
  padding-left: 25px;
  cursor: pointer;
  position: relative;
}
.order-status-radio-item label:before, 
.order-status-radio-item label:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}
.order-status-radio-item label:after {
  height: 18px;
  width: 18px;
  border: 2px solid #d89700;
  left: 0;
  top: calc(50% - 9px);
}

.order-status-radio-item label:before {
  background: #d89700;
  height: 10px;
  width: 10px;
  left: 4px;
  top: calc(50% - 5px);
  transform: scale(3);
  opacity: 0;
  visibility: hidden;
  transition: .4s ease-in-out 0s;
}
.order-status-radio-item input[type="radio"]:checked ~ label:before {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
/* Custom Radio Button END */
.order-status-select-mobile {
  border: 1px solid #D89700;
  color: #D89700;
  background: #fff;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 24px;
  display: inline-block;
}
.order-status-badge-list {
  align-items: center;
  gap: 8px;
}
.order-status-badge-list.is-loading span, .order-status-select-mobile.is-loading {
  pointer-events: none;
  opacity: 0.6;
}
.order-status-label {
  border: 1px solid #D89700;
  color: #D89700;
  background: #fff;
  cursor: pointer;
  padding: 8px 18px;
  font-size: 18px;
  border-radius: 24px;
  margin-bottom: 0;
}
.order-status-label:hover {
  color: #AC7800;
}
input[type="radio"].order-status-badge:checked + .order-status-label {
  color: #fff;
  background: #D89700;
}
.pickup-code {
  color: #4caf50;
}
.product {
  display: flex;
  margin-bottom: 0.5rem;
}
.order-number {
  margin-left: 5.5px;
}
.p-16 {
  padding: 16px 0px;
}
.btn-wz-cancel,
.btn-wz-clear,
.btn-wz-transparent {
  letter-spacing: normal;
  font-weight: bold;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn-wz-transparent {
  width: fit-content;
}

/* mobile view */
@media only screen and (max-width: 992px) {
  .btn {
    padding: 12px;
  }
  .btn-w-100 {
    width: 100%;
  }
  .btn-w-48 {
    width: 48%;
  }
  .price,
  .payment-detail,
  .recent-order-info,
  .pickup-code,
  .preorder-estimated {
    font-size: 12px;
  }
  .product-brand-big,
  .status-tags,
  .recent-order-payment {
    font-size: 14px;
  }
}

</style>